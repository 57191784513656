const {
  FETCH_ALL_MULTI_LANG_CONTENTS_QUERY,
  FETCH_MULTI_LANG_CONTENTS_BY_KEY_QUERY,
  INSERT_MULTI_LANG_CONTENT,
  UPDATE_MULTI_LANG_CONTENT,
} = require("../graphql/multi-lang")
const { makeGraphRequest } = require("../utils/request")

class MultiLangService {
  getMultiLangContents() {
    return makeGraphRequest(FETCH_ALL_MULTI_LANG_CONTENTS_QUERY).then(
      data => data.courses_multi_lang_contents
    )
  }
  getMultiLangContentsByKey(key) {
    return makeGraphRequest(FETCH_MULTI_LANG_CONTENTS_BY_KEY_QUERY, {
      key,
    }).then(data => data.courses_multi_lang_contents[0])
  }
  setMultiLangContent(data) {
    const { key, content, id } = data
    if (id)
      return makeGraphRequest(UPDATE_MULTI_LANG_CONTENT, {
        id,
        object: {
          id,
          key,
          content,
        },
      }).then(data => data.update_courses_multi_lang_contents.returning[0])
    else
      return makeGraphRequest(INSERT_MULTI_LANG_CONTENT, {
        object: { key, content },
      }).then(data => data.insert_courses_multi_lang_contents.returning[0])
  }
}

const mlSvc = new MultiLangService()

module.exports = mlSvc
