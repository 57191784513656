import React, { createContext } from "react"
import { languages } from "../../config"
import { window } from "browser-monads"
import { navigate } from "gatsby"

export const NavigationContext = createContext()

export default function NavigationContextProvider(props) {
  const { current } = props

  let currentLanguage,
    currentPath

    // Determine current path and lang
  ;(function () {
    const { pathname } = current

    const chunk1 = pathname.split("/")

    let lang = Object.values(languages).find(e => e === chunk1[1]) || ""
    let path = ""

    if (lang) {
      path = "/" + chunk1.slice(2).join("/")
    } else {
      lang = "en"
      path = pathname
    }

    currentPath = path
    currentLanguage = lang
  })()

  const setLanguage = code => {
    let link = currentPath
    if (code != "en") {
      link = "/" + code + link
    }
    navigate(link)
  }

  const setPath = path => {
    if (path[0] === "/") {
      navigate(
        `${currentLanguage === "en" ? "" : "/" + currentLanguage}${path}`
      )
    } else {
      navigate(window.location.pathname + "/" + path)
    }
  }

  const content =
    typeof props.children === "function"
      ? props.children({ currentLanguage, currentPath })
      : props.children

  return (
    <NavigationContext.Provider
      value={{
        currentLanguage,
        currentPath,
        setLanguage,
        setPath,
      }}
    >
      {content}
    </NavigationContext.Provider>
  )
}
