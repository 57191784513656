import React, { useContext, useRef } from "react"
import Modal from "./Modal"
import { AuthContext } from "../context/Auth"

export default function LoginModal(props) {
  const { login } = useContext(AuthContext)

  const identifierRef = useRef()
  const passwordRef = useRef()

  const handleSubmit = e => {
    e.preventDefault()

    const identifier = identifierRef.current.value
    const password = passwordRef.current.value

    login(identifier, password).then(props.onClose)
  }

  return (
    (props.isOpen && (
      <Modal onClose={props.onClose}>
        <form onSubmit={handleSubmit}>
          <div
            style={{
              display: "grid",
              gridGap: "10px",
              gridTemplateColumns: "150px auto",
            }}
          >
            <b>Username / Email:</b>{" "}
            <input
              className={"input-primary"}
              type={"text"}
              ref={identifierRef}
            />
            <b>Password:</b>{" "}
            <input
              className={"input-primary"}
              type={"password"}
              ref={passwordRef}
            />
          </div>
          <input type={"submit"} value={"Login"} />
        </form>
      </Modal>
    )) ||
    null
  )
}
