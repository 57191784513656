import React, { useContext, useState } from "react"
import { MultiLangContext } from "skillstrainer-resource-library"
import { AuthContext } from "../context/Auth"
import LoginModal from "./LoginModal"
import { adminHost } from "../../config"
import { window } from "browser-monads"

const EditingPanelView = ({
  editingEnabled,
  setEditingEnabled,
  submit,
  logout,
  user,
  userLoggedIn,
  changesSaved,
  publishChanges,
}) =>
  (
    <div
      className={"w-screen fixed px-5 flex justify-between z-20"}
      style={{ background: "#ddd", height: "40px" }}
    >
      {userLoggedIn ? (
        <>
          <div className={"flex items-center"}>
            <div
              style={{
                paddingRight: "10px",
                marginRight: "10px",
                borderRight: "1px solid #444",
                fontWeight: "bold",
              }}
            >
              {user.name || user.email}
            </div>{" "}
            <button onClick={logout}>Logout</button>
          </div>
          <div className={"flex"}>
            <button
              onClick={() => setEditingEnabled(!editingEnabled)}
              className={"mr-5"}
            >
              {editingEnabled ? "Preview" : "Edit"}
            </button>
            <button onClick={() => window.location.reload()} className={"mr-5"}>
              Revert all changes
            </button>
            <button onClick={submit} className={"mr-5"}>
              Save changes
            </button>
            {changesSaved && (
              <button onClick={publishChanges}>Publish changes</button>
            )}
          </div>
        </>
      ) : (
        <>
          <div className={"flex items-center"}>
            <b className={"mr-5"}>Please log in to edit the website</b>
            <button
              onClick={() =>
                typeof window !== "undefined" &&
                (window.location.href = adminHost)
              }
              className={"button-primary"}
            >
              Log in
            </button>
          </div>
        </>
      )}
    </div>
  ) || null

export default function EditingPanel(props) {
  const { submit, changesSaved, publishChanges } = useContext(MultiLangContext)
  const {
    user,
    userLoggedIn,
    logout,
    editingEnabled,
    setEditingEnabled,
  } = useContext(AuthContext)
  const [isLoginOpen, setIsLoginOpen] = useState(false)

  return (
    <>
      <EditingPanelView
        editingEnabled={editingEnabled}
        setEditingEnabled={setEditingEnabled}
        changesSaved={changesSaved}
        publishChanges={publishChanges}
        submit={submit}
        setIsLoginOpen={setIsLoginOpen}
        user={user}
        userLoggedIn={userLoggedIn}
        logout={logout}
      />
      <LoginModal isOpen={isLoginOpen} onClose={() => setIsLoginOpen(false)} />
    </>
  )
}
