import React from "react"
import "./src/assets/styles.css"
import "./src/assets/resources.css"

import { StateProvider } from "./src/context/stateProvider"
import NavigationContextProvider from "./src/context/Navigation"
import AuthContextProvider from "./src/context/Auth"
import EditingPanel from "./src/components/EditingPanel"
import { editable, env } from "./config"
import mlSvc from "./src/services/MultiLang"
import { STRLContextProvider } from "skillstrainer-resource-library"
import CourseContextProvider from "./src/context/Course"

const initialMultiLangData = require("./src/data.json").allContent

console.log("App environment", env)

export const wrapPageElement = ({ props, element }) => {
  return (
    <AuthContextProvider>
      {({ editingEnabled, jwtToken, accessToken, userLoggedIn }) => {
        const authCheckComplete =
          !editable || (editable && userLoggedIn !== null)
        return (
          <NavigationContextProvider current={props.location}>
            {({ currentLanguage: lang, currentPath: path }) =>
              authCheckComplete && (
                <STRLContextProvider
                  multiLang={{
                    initialData: initialMultiLangData,
                    loadContentFn: ({ key }, resolve, reject) =>
                      mlSvc
                        .getMultiLangContentsByKey(key)
                        .then(resolve)
                        .catch(reject),
                    submitContentFn: async (data, resolve, reject) =>
                      mlSvc
                        .setMultiLangContent(data)
                        .then(resolve)
                        .catch(reject),
                    enabled: editingEnabled,
                    isInEditableMode: editable,
                  }}
                  request={{
                    env,
                    jwtToken,
                    accessToken,
                  }}
                >
                  {editable && (
                    <>
                      <EditingPanel />
                      <div
                        style={{
                          overflow: "auto",
                          width: "100vw",
                          height: "40px",
                          position: "relative",
                        }}
                      />
                    </>
                  )}
                  <StateProvider>
                    <CourseContextProvider>{element}</CourseContextProvider>
                  </StateProvider>
                </STRLContextProvider>
              )
            }
          </NavigationContextProvider>
        )
      }}
    </AuthContextProvider>
  )
}
