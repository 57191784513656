import React, {
  useContext,
  useReducer,
  createContext,
  useEffect,
  useState,
} from "react"

export const StateContext = createContext()

export const StateProvider = ({ children }) => {
  const globalWindow = typeof window !== "undefined" && window
  const [courseName, setCourseName] = useState("")
  const [Category, setCategory] = useState("")
  const [hidden, setHidden] = useState(true)
  const [activePage, setActivePage] = useState(() => {
    const globalWindow = typeof window !== "undefined" && window
    const localData =
      globalWindow && globalWindow.localStorage.getItem("activePage")
    return localData ? JSON.parse(localData) : ""
  })
  const [language, setLanguage] = useState(() => {
    const localData =
      globalWindow && globalWindow?.localStorage.getItem("currentLanguage")
    return localData ? JSON.parse(localData) : "en"
  })
  useEffect(() => {
    globalWindow?.localStorage.setItem(
      "currentLanguage",
      JSON.stringify(language)
    )
  }, [language])

  useEffect(() => {
    globalWindow?.localStorage.setItem("activePage", JSON.stringify(activePage))
  }, [activePage])

  return (
    <StateContext.Provider
      value={{
        language,
        setLanguage,
        courseName,
        setCourseName,
        Category,
        setCategory,
        setActivePage,
        activePage,
        hidden,
        setHidden,
      }}
    >
      {children}
    </StateContext.Provider>
  )
}

export const useStateProviderValue = () => useContext(StateContext)
