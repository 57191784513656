const MULTI_LANG_CONTENT_FIELDS = `
  id
  key
  content
`

module.exports.FETCH_ALL_MULTI_LANG_CONTENTS_QUERY = `
  query {
    courses_multi_lang_contents {
      ${MULTI_LANG_CONTENT_FIELDS}
    }
  }
`

module.exports.FETCH_MULTI_LANG_CONTENTS_BY_KEY_QUERY = `
  query ($key: String) {
    courses_multi_lang_contents (
      where: {
        key: {
          _eq: $key
        }
      }
    ) {
      ${MULTI_LANG_CONTENT_FIELDS}
    }
  }
`

module.exports.INSERT_MULTI_LANG_CONTENT = `
  mutation ($object: [courses_multi_lang_contents_insert_input!]!) {
    insert_courses_multi_lang_contents (objects: $object) {
      returning {
        ${MULTI_LANG_CONTENT_FIELDS}
      }
    }
  }
`
module.exports.UPDATE_MULTI_LANG_CONTENT = `
  mutation ($id: bigint, $object: courses_multi_lang_contents_set_input!) {
    update_courses_multi_lang_contents (
      where: { id: { _eq: $id }},
      _set: $object
    ) {
      returning {
        ${MULTI_LANG_CONTENT_FIELDS}
      }
    }
  }
`
