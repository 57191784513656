import React, { useState, useEffect, createContext } from "react"
import config, { editable } from "../../config"
import _ from "lodash"
import requestUtils from "../utils/request"

export const AuthContext = createContext()

export default function AuthContextProvider(props) {
  const {
    setToken,
    clearToken,
    validatePythonToken,
    setAccessToken,
    getToken,
    getAccessToken,
  } = requestUtils

  const [user, _setUser] = useState(null)
  const setUser = user => {
    if (typeof localStorage !== "undefined") {
      if (!user) localStorage.removeItem("user")
      else localStorage.setItem("user", JSON.stringify(user))
      _setUser(user)
    }
  }
  const [userLoggedIn, setUserLoggedIn] = useState(null)

  const setTokenAndUser = (token, accessToken, user) => {
    if (token) {
      setToken(token)
      setAccessToken(accessToken)
      setUser(user)
      setUserLoggedIn(true)
    } else {
      clearToken()
      setUser(false)
      setUserLoggedIn(false)
    }
  }

  const checkDefaultToken = async () => {
    let token, accessToken, user
    let isTokenValid
    if (typeof localStorage !== "undefined") {
      token = localStorage.getItem("token")
      isTokenValid = await validatePythonToken(token)
      if (isTokenValid) {
        accessToken = localStorage.getItem("accessToken")
        user = JSON.parse(localStorage.getItem("user"))
      }
    }

    if (isTokenValid) setTokenAndUser(token, accessToken, user)
    else setTokenAndUser(false, false, false)
  }
  if (typeof window !== "undefined")
    window.checkDefaultToken = checkDefaultToken

  useEffect(() => {
    if (config.editable) checkDefaultToken()
  }, [])

  // const login = (identifier, password) =>
  //   makePostRequest("/auth/local", { identifier, password })
  //     .then(res => {
  //       setTokenAndUser(res.jwt, res.user)
  //       return true
  //     })
  //     .catch(() => window.alert("Error! Couldn't log in"))

  const logout = () => setTokenAndUser("", "")

  const [editingEnabled, setEditingEnabled] = useState(false)

  useEffect(() => {
    if (editable && !_.isEmpty(user)) setEditingEnabled(true)
    else setEditingEnabled(false)
  }, [user])

  return (
    <AuthContext.Provider
      value={{
        user,
        userLoggedIn,
        setTokenAndUser,
        jwtToken: getToken(),
        accessToken: getAccessToken(),
        logout,

        editingEnabled,
        setEditingEnabled,
      }}
    >
      {typeof props.children === "function"
        ? props.children({
            editingEnabled,
            jwtToken: getToken(),
            accessToken: getAccessToken(),
            userLoggedIn,
          })
        : props.children}
    </AuthContext.Provider>
  )
}
