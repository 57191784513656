const partialObject = (condition, partial) => (condition ? partial : {})

const toIndianNumber = number =>
  (number + "")
    .split("")
    .reverse()
    .map((_, index, arr) =>
      index && index % 2 === 0 && index + 1 < arr.length ? `,${_}` : _
    )
    .reverse()
    .join("")

module.exports = { partialObject, toIndianNumber }
