import React, { createContext, useEffect, useState } from "react"
import courseSvc from "../services/Course"
import { coursesByField as allCourses } from "../data.json"

export const CourseContext = createContext()

export default function CourseContextProvider(props) {
  const [coursesUsers, setCoursesUsers] = useState({})

  const fetchAllCoursesUsers = () =>
    courseSvc
      .getCoursesEnrolledUsers()
      .then(res => {
        const coursesUsers = allCourses.reduce((final, cur) => {
          const resCourse = res.find(
            e => e.moodle_course_id === cur.moodleCourseId
          )
          if (resCourse) final[cur.courseId] = resCourse.total_users
          return final
        }, {})
        setCoursesUsers(coursesUsers)
      })
      .catch(() => {})

  const fetchCourseUsers = async courseId => {
    const course = allCourses.find(c => c.courseId == courseId)
    if (course) {
      const usersCount = await courseSvc
        .getCourseEnrolledUsers({
          isMoodleCourse: course.isMoodleCourse,
          id: course.courseId,
          moodleCourseId: course.moodleCourseId,
        })
        .catch(() => {})
      if (!usersCount && usersCount !== 0) {
        coursesUsers[course.courseId] = usersCount
        setCoursesUsers({ ...coursesUsers })
      }
      return usersCount
    }
  }

  useEffect(() => fetchAllCoursesUsers(), [])

  return (
    <CourseContext.Provider
      value={{ coursesUsers, fetchAllCoursesUsers, fetchCourseUsers }}
    >
      {props.children}
    </CourseContext.Provider>
  )
}
