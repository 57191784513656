const _ = require("lodash")

const formatConfig = config => {
  let envConfig

  // Returns the environment based value
  const getModeValue = (
    development,
    staging,
    production,
    env = envConfig && envConfig.env
  ) => ({ development, staging, production }[env || "development"])

  if (!config.beingConsumedByApp) {
    try {
      const fs = require("fs")
      const env = process.env.TESTING

      envConfig = {
        env,
        editable: !!process.env.EDITABLE,
      }

      const fileContents = JSON.stringify(envConfig, null, 2)
      fs.writeFileSync("./src/env.json", fileContents)
    } catch (err) {
      console.error("Error : config-formatter", JSON.stringify(err))
    }
  } else envConfig = require("../env.json")

  const { env, editable } = envConfig

  const backendHost = getModeValue(
    config.api.dev,
    config.api.staging,
    config.api.production
  )

  const pythonBackendHost = getModeValue(
    config.pythonApi.dev,
    config.pythonApi.staging,
    config.pythonApi.production
  )

  const graphHost = getModeValue(
    config.graph.dev,
    config.graph.staging,
    config.graph.production
  )

  const adminHost = getModeValue(
    config.admin.dev,
    config.admin.staging,
    config.admin.production
  )

  const webappHost = getModeValue(
    config.webapp.dev,
    config.webapp.staging,
    config.webapp.production
  )

  const s3BaseUrl = getModeValue(
    config.s3BaseUrl.dev,
    config.s3BaseUrl.staging,
    config.s3BaseUrl.production
  )

  const exportable = {
    ...config,
    env,
    isProduction: env === "development",
    isStaging: env === "staging",
    partnerId: process.env.PARTNER_ID,

    backendHost,
    remoteBackend: config.api.production,
    localBackend: config.api.dev,
    pythonBackendHost,
    graphHost,
    webappHost,
    adminHost,
    s3BaseUrl,

    editable,
  }

  return exportable
}

module.exports = { formatConfig }
